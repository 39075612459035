.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.otp input {
  width: 50px !important;
  height: 50px !important;
  left: 449px;
  top: 418px;
  border: 2px solid #000000;
  box-sizing: border-box;
  border-radius: 15px;
  margin-left: 20px;
}

.box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(max(195px, 45vw), 2fr));
  margin: 10px;
  grid-gap: 40px;
}

.box > * {
  height: 80vh;
}

.timeline-container {
  width: 70%;
  margin: 0 auto;
  background: white;
}

.history-tl-container {
  margin: auto;
  display: block;
  position: relative;
}
.history-tl-container ul.tl {
  margin: 60px 0 20px 0;
  padding: 0;
  display: inline-block;
}
.history-tl-container ul.tl li {
  list-style: none;
  margin: auto;
  margin-left: 20px;
  min-height: 50px;
  padding: 0 0 60px 5px;
  position: relative;
  cursor: pointer;
}

.history-tl-container ul.tl li:last-child {
  border-left: 0;
}

.complete-seller-info-1::before {
  position: absolute;
  left: -12px;
  top: -5px;
  content: '02';
  color: rgba(134, 97, 255, 1);
  border: 2px solid rgba(134, 97, 255, 1);
  padding: 5px;
  border-radius: 50%;
  background: rgb(237, 231, 255);
  height: 35px;
  width: 35px;
  transition: all 500ms ease-in-out;
  margin-left: -5px;
  padding: 3px;
}

.complete-bank-info::before {
  position: absolute;
  left: -12px;
  top: -5px;
  content: '04';
  color: rgba(134, 97, 255, 1);
  border: 2px solid rgba(134, 97, 255, 1);
  padding: 5px;
  border-radius: 50%;
  background: rgb(237, 231, 255);
  height: 35px;
  width: 35px;
  transition: all 500ms ease-in-out;
  margin-left: -5px;
  padding: 3px;
}

.complete-seller-business-info::before {
  position: absolute;
  left: -12px;
  top: -5px;
  content: '03';
  color: rgba(134, 97, 255, 1);
  border: 2px solid rgba(134, 97, 255, 1);
  padding: 5px;
  border-radius: 50%;
  background: rgb(237, 231, 255);
  height: 35px;
  width: 35px;
  transition: all 500ms ease-in-out;
  margin-left: -5px;
  padding: 3px;
}

.verify-phone-num::before,
.complete-seller-info-1-done::before {
  position: absolute;
  left: -12px;
  top: -5px;
  content: '✓';
  color: white;
  border: 7px solid rgba(134, 97, 255, 1);
  border-radius: 500%;
  background: rgba(134, 97, 255, 1);
  height: 35px;
  width: 35px;
  transition: all 500ms ease-in-out;
  margin-left: -5px;
}

.item-title,
.item-detail {
  text-align: left;
  margin-left: 35px;
}

.history-tl-container ul.tl li .item-detail {
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
}

.history-tl-container ul.tl li .timestamp {
  color: #8d8d8d;
  position: absolute;
  width: 100px;
  left: -50%;
  text-align: right;
  font-size: 12px;
}

.border-red {
  border: 1px solid red;
}

.error-span {
  font-size: 12px;
  color: red;
}

.file_upload {
  opacity: 0;

  /* IE 8 */
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

#getFile {
  display: none;
}

.react-date-picker {
  display: inline-flex;
  position: relative;
  width: 100%;
}
.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-date-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}
.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
}
.react-date-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
  margin-right: 4rem;
}
.react-date-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}
.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-date-picker__inputGroup__input:invalid {
  background: rgba(0, 0, 0, 0.1);
}
.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}
.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}
.react-date-picker__button:enabled {
  cursor: pointer;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7;
}
.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}
.react-date-picker__button svg {
  display: inherit;
}
.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}

.react-date-picker__calendar-button {
  position: absolute;
  right: 1rem;
}

.react-date-picker__clear-button {
  position: absolute;
  right: 3rem;
}

.react-date-picker__calendar--closed {
  display: none;
}
.react-date-picker__calendar .react-calendar {
  border-width: thin;
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  z-index: 60;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  @apply bg-purple-500;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  @apply bg-purple-500;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
